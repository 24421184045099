import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Crousel() {

  const [banners, setBanners] = useState([]);


    const getUsers = async () => {
        const { data } = await axios.get('http://api.compliancekare.com/api/Compliance/BannerList');
        setBanners(data.data);
    }

    useEffect(() => {
        getUsers();
    }, []);





  return (
    <div  style={{border:"1px solid #88c763"}}>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {banners.map((banner, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0}
              aria-label={`Slide ${index + 1}`}
            />
          ))}
        </div>
        <div className="carousel-inner" >
          {banners.map((banner, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <img  src={`http://admin.compliancekare.com/BannerImage/${banner.bannerImage}`}
               className="d-block w-100" alt="..." />
              {/* <div className="carousel-caption d-none d-md-block">
                <h5>{banner.title}</h5>
                <p>{banner.description}</p>
              </div> */}
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>

 
  );
}

export default Crousel;
