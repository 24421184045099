import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Css/LoginPage.css';
import TopHeader from '../Header/TopHeader';
import Navbar from '../Header/Navbar';
import Swal from 'sweetalert2';
import axios from 'axios'; // Import axios

function Login() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Function to handle login and store coupon code
    const handleLogin = async (loginData) => {
        try {
            const response = await axios.post('https://api.compliancekare.com/api/ApiAccount/Login', loginData);
            if (response.data.response.succeeded) {
                const { vendorIdOrCoupon } = response.data.data;
                sessionStorage.setItem('couponCode', vendorIdOrCoupon);
                sessionStorage.setItem('token', response.data.token); // Store the token as well

                Swal.fire({
                    title: "Success!",
                    text: response.data.response.message,
                    icon: "success",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/');
                    }
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    text: response.data.response.message || "Invalid username or password.",
                    icon: "error",
                });
            }
        } catch (error) {
            console.error('Login failed:', error);
            Swal.fire({
                title: "Error!",
                text: "Something went wrong. Please try again later.",
                icon: "error",
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin(formData);
    };

    return (
        <>
            <TopHeader />
            <Navbar />
            <div className="wrapper">
                <div className="container1 p-4">
                    <div className="col-left">
                        <div className="login-text" style={{ marginLeft: "-2rem" }}>
                            <h2>Compliancekare</h2>
                            <p>File your ITR online in just 4 minutes</p>
                            <div style={{ display: 'flex' }}>
                                <div>
                                   
                                </div>
                                &nbsp; &nbsp;
                                <div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-right">
                        <div className="login-form">
                            <h2>Login</h2>
                            <form onSubmit={handleSubmit}>
                                <p>
                                    <input
                                        type="text"
                                        name="username"
                                        placeholder="Username"
                                        required
                                        onChange={handleInputChange}
                                    />
                                </p>
                                <p>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        required
                                        onChange={handleInputChange}
                                    />
                                </p>
                                <div>
                                    <p>
                                        <button className="btn btn-success" type="submit">Login</button>
                                    </p>
                                </div>
                                <p>
                                    <a href="">Forget password?</a>
                                    <Link to="/Signup">Create an account.</Link>
                                </p>
                                <a href="https://admin.compliancekare.com/" target='blank' style={{ color: "#89c565" }}>Vendor Login</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
