import React, { useState } from 'react';
import axios from 'axios';
import TopHeader from '../Header/TopHeader';
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';
import '../Css/Contact.css';

function Contact() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(null);

        const contactData = {
            fullName,
            email,
            message,
            contactDate: new Date().toISOString()
        };

        try {
            const response = await axios.post('https://api.compliancekare.com/api/CommonApi/AddContactUs', contactData);
            if (response.status === 200) {
                setSuccess('Message sent successfully!');
                setFullName('');
                setEmail('');
                setMessage('');
            }
        } catch (error) {
            setSuccess('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <TopHeader />
            <Navbar />
            <section style={{ background: "white", padding: "15px" }}>
                <div className="section-header">
                    <div className="container">
                        <h2 style={{ color: "black", fontFamily: "poppins", fontSize: "1.6rem" }}>Contact Us</h2>
                        <p style={{ color: "black", fontFamily: "poppins", fontSize: "20px" }}>If You have query? Write here</p>
                    </div>
                </div>

                <div className="container">
                    <div className="row" style={{ display: "flex" }}>
                        <div className="contact-info">
                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fas fa-home"></i>
                                </div>
                                <div className="contact-info-content">
                                    <h4 style={{ fontFamily: "poppins", fontSize: "25px" }}>Address</h4>
                                    <p style={{ fontFamily: "poppins", fontSize: "20px" }}>
                                        513, Naurang House, 21 K.G. Marg, <br />
                                        Connaught Place, New Delhi 110001
                                    </p>
                                </div>
                            </div>

                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fas fa-phone"></i>
                                </div>
                                <div className="contact-info-content">
                                    <h4 style={{ fontFamily: "poppins", fontSize: "25px" }}>Landline No</h4>
                                    <p style={{ fontFamily: "poppins", fontSize: "20px" }}>+91 11 4760 2267</p>
                                </div>
                                <div className="contact-info-content">
                                    <h4 style={{ fontFamily: "poppins", fontSize: "25px" }}>Mobile No</h4>
                                    <p style={{ fontFamily: "poppins", fontSize: "20px" }}>+91 9625758585</p>
                                </div>
                            </div>

                            <div className="contact-info-item">
                                <div className="contact-info-icon">
                                    <i className="fas fa-envelope"></i>
                                </div>
                                <div className="contact-info-content">
                                    <h4 style={{ fontFamily: "poppins", fontSize: "25px" }}>Email</h4>
                                    <p style={{ fontFamily: "poppins", fontSize: "20px" }}>info@compliancekare.com</p>
                                </div>
                            </div>
                        </div>

                        <div className="contact-form">
                            <form onSubmit={handleSubmit} id="contact-form">
                                <h2 style={{ color: "rgb(137, 199, 100)" }}>Send Message</h2>

                                <div className="input-box">
                                    <input
                                        type="text"
                                        required
                                        value={fullName}
                                        placeholder='Full Name'
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                   
                                </div>

                                <div className="input-box">
                                    <input
                                        type="email"
                                        required
                                        placeholder='Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                   
                                </div>

                                <div className="input-box">
                                    <textarea
                                        required
                                        value={message}
                                        placeholder='Type your Message...'
                                        onChange={(e) => setMessage(e.target.value)}
                                    ></textarea>
                                    
                                </div>

                                <div className="input-box">
                                    <input type="submit" value={loading ? "Sending..." : "Send"} disabled={loading} />
                                </div>

                                {success && <p>{success}</p>}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Contact;
