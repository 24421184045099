import React, { useState } from 'react';
import '../Css/Faq.css';
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';
import '@fortawesome/fontawesome-free/css/all.min.css';

function Faq() {
    const [activeAccordion, setActiveAccordion] = useState(null);

    const toggleAccordion = (index) => {
        if (activeAccordion === index) {
            setActiveAccordion(null); // Collapse if it's already open
        } else {
            setActiveAccordion(index); // Open if it's closed
        }
    };

    // Example FAQ data
    const faqData = [
        {
            question: 'What is e-Filing of income tax returns?',
            answer: 'Lorem Ipsum is simply dummy text...',
        },
        {
            question: 'Who should file an Income Tax Return?',
            answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
        },
        {
            question: 'Where can I file an Income Tax Return in India??',
            answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
        },
        {
            question: 'What if I miss the due date of filing the Income Tax Return?',
            answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
        },
        {
            question: 'How to file an Income Tax Return online?',
            answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
        },
        {
            question: 'How to e-file ITR with Form 16?',
            answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
        },
        {
            question: 'Can I e-file ITR without Form 16?',
            answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
        },
        {
            question: 'What are the penalties for not filing Income Tax Return in India?',
            answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
        },
        {
            question: 'What will be the next steps after e-filing of Income Tax Return?',
            answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
        },
        {
            question: 'What will be the next steps after e-filing of Income Tax Return?',
            answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text...',
        },
        // Add more FAQ items as needed
    ];

    return (
        <>

            <Navbar />
            <section className="faq-section">
                <div className="container">
                    <div className="row">
                        <h2 style={{ color: "black", textAlign: "center", fontFamily: "poppins" }}>FAQs</h2>
                        <p style={{ color: "black", textAlign: "center", color: "#30909d", fontFamily: "poppins" }}>Answers to the most frequently asked questions.</p>
                        <div className="offset-md-12">
                            <div className="faq" id="accordion">
                                {faqData.map((item, index) => (
                                    <div className="card" key={index}>
                                        <div className="card-header" id={`faqHeading-${index}`}>
                                            <div className="mb-0">
                                                <h5 className="faq-title" onClick={() => toggleAccordion(index)} style={{ fontFamily: "poppins", fontSize: "20px" }}>
                                                    <span className="badge">{index + 1}</span>
                                                    {item.question}
                                                    <span className={`arrow ${activeAccordion === index ? 'open' : ''}`}></span>
                                                </h5>

                                            </div>
                                        </div>
                                        <div
                                            className={`collapse ${activeAccordion === index ? 'show' : ''}`}
                                            aria-labelledby={`faqHeading-${index}`}
                                            data-parent="#accordion"
                                        >
                                            <div className="card-body" style={{ fontFamily: "poppins", fontSize: "20px" }}>
                                                Ans-
                                                {item.answer}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </section>

            <Footer />
        </>
    );
}

export default Faq;


