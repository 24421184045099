import React from 'react'

import TopHeader from '../Header/TopHeader'
import Navbar from '../Header/Navbar'
import Crousel from '../Screens/Crousel'
import IncomeTaxReturn from '../Screens/IncomeTaxReturn'
import MapBanner from '../Screens/MapBanner'
import Banner from '../Screens/Banner'
import PopularService from '../Screens/PopularService'
import Footer from '../Footer/Footer'

import PopularGuide from '../Screens/PopularGuide'
import TaxServices from '../Screens/TaxServices'
import WhyChooseUs from '../Screens/WhyChooseUs'

function Main() {
  return (
    <>
      <TopHeader />
      <Navbar />
      
      <Crousel />
      {/* <IncomeTaxReturn /> */}
      <MapBanner />
      {/* <Banner /> */}
     
      <PopularGuide/>
      <TaxServices/>
      <WhyChooseUs/>
     
      <Footer />

    </>
  )
}

export default Main