import React from 'react'
import { Link } from 'react-router-dom'
import TopHeader from '../Header/TopHeader'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import PopularService from './PopularService'
import PopularSearch from './PopularSearch'

function Review() {
    return (
        <>
            <TopHeader />
            <Navbar />

            <section className='container'>
                <div class="row ">
                    <div class="col">
                        <div >
                            <h1 class="text-left">Customer Reviews</h1>
                            <p style={{ textAlign: "justify" }}>
                                Everyday we serve thousands of Entrepreneurs across the world in helping them start and grow their business in India. We take pride in the services delivered by us and guarantee your satisfaction with our services and support. However, in case you are not satisfied with our services, please contact us immediately at <a href="#">info@compliancekare.com </a>and we will do our best to correct the situation.
                            </p>
                            <div class="d-flex ">
                                <div>
                                    <img class="w-25" src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRWQo2mO-e6fxAp4hhXjaR8mVcpMelAimzUdNVAzvBNcNjtErWS" alt="icon" />
                                    <h4 style={{ fontFamily: "poppins" }}>4.88
                                        <br />
                                        Average Rating
                                    </h4>

                                </div>
                                <div>
                                    <img class="w-25" src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRvYF_lutPfAZMh2KY94UMjujq_dK4qZWfgalGK1VYElUmV_Nxq" alt="icon" />
                                    <h4 style={{ fontFamily: "poppins" }}>106139
                                        <br />
                                        Total Reviews
                                    </h4>

                                </div>
                                <div>
                                    <img class="w-25" src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSkzYMfBLYl7YiF6CBCiy9ox-spX05hEotCl93-JMlGiMPm9KgC" alt="icon" />
                                    <h4 style={{ fontFamily: "poppins" }}>30000+
                                        <br />
                                        Customers Served
                                    </h4>

                                </div>
                                <div>
                                    <img class="w-25" src="https://t3.gstatic.com/images?q=tbn:ANd9GcSh49vv25rgTomhCXd3Xna-fQhU448TV1TtO5brXrD9UdzHG9-P" alt="icon" />
                                    <h4 style={{ fontFamily: "poppins" }}>400000+
                                        <br />
                                        Entrepreneurs Served
                                    </h4>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <img src="https://media.istockphoto.com/id/867629246/photo/portrait-of-asian-businesswoman-isolated-on-wthie-background.webp?b=1&s=170667a&w=0&k=20&c=g6V9-4bSAzcktBYCsJc6Fw5whPPKwsoL6GK5ha0Wihc=" alt="image" />
                    </div>


                </div>
            </section>

            <section>
                <div className='container p-4'>
                    <h3 className='text-center'>Customer Reviews For Proprietorship</h3>
                    <p className='text-center'>ComplianceKare has over 1 lakh customers and we have completed over 7 lakh services through our platform. At IndiaFilings, we take pride in the services delivered by us and guarantee your satisfaction with our services and support. We constantly improve and strive to deliver the best accounting, financial or secretarial services through the internet.</p>
                </div>
                <div className='container mb-5'>

                    <div class="row">
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Vishnu Tiwari</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Rahul Singh</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Kartik </h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Prince</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Shubham</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>

                    </div>
                    <br />
                    <div class="row">
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Anchal</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Madhu</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Vishal </h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Rakesh</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Akansha</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>

                    </div>
                    <br />
                    <div class="row">
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Anchal</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Madhu</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Vishal </h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Rakesh</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Akansha</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>

                    </div>
                    <br />
                    <div class="row">
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Anchal</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Madhu</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Vishal </h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Rakesh</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Akansha</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>

                    </div>
                    <br />
                    <div class="row">
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Anchal</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Madhu</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Vishal </h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Rakesh</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Akansha</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>

                    </div>
                    <br />


                    <div class="row">
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Anchal</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Madhu</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Vishal </h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Rakesh</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>
                        <div class="col">

                            <div class="card" >

                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="ratings">
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                            <i class="fa fa-star" style={{ color: "#fbd205" }}></i>
                                        </div>

                                    </div>
                                    <h5 class="card-title">Akansha</h5>
                                    <h6 style={{ color: "black!important" }}>Proprietorship</h6>
                                    <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                        <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" />
                                        <p>Verified Customer</p>
                                    </li>
                                    <p>6/11/2020</p>
                                    <p style={{ color: "#3a7ab2" }}>How can we improve?</p>
                                    <p class="card-text">Thanks for done the process ASAP</p>

                                </div>
                            </div>

                        </div>

                    </div>
                    <br />

                </div>

                <PopularService />
                <PopularSearch />





            </section>







            <Footer />



        </>
    )
}

export default Review