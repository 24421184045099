import React from 'react'

function PopularSearch() {
    return (
        <>
            <div class="container p-4">
                <div class="row">
                    <div class="col-lg-12">
                        <h3>Popular Searches</h3>
                        <hr style={{ height: "0.5px", border: "none", color: "#C0CBD9;", backgroundColor: "black" }} />
                    </div>
                    <div class="col-lg-12">
                        <p style={{ lineHeight: "30px", textAlign: "justify" }}>
                            <span><a href="">One Person Company</a> | </span>
                            <span><a href="">Nidhi Company</a> | </span>
                            <span><a href="">Company Registration</a> | </span>
                            <span><a href="">Tax Notice</a> | </span>
                            <span><a href="">Indian Subsidiary</a> | </span>
                            <span><a href="">HSN Code</a> | </span>
                            <span><a href="">Section 8 Company</a> | </span>
                            <span><a href="">Trademark Search</a> | </span>
                            <span><a href="">USA Company Registration</a> | </span>
                            <span><a href="">FSSAI Registration</a> | </span>
                            <span><a href="">Import Export Code</a> | </span>
                            <span><a href="">ESI Registration</a> | </span>
                            <span><a href="">Proprietorship</a> | </span>
                            <span><a href="">GST Return Filing</a> | </span>
                            <span><a href="">Patta Chitta</a> | </span>
                            <span><a href="">PF Registration</a> | </span>
                            <span><a href="">Payroll</a> | </span>
                            <span><a href="">Business Tax Return Filing</a> | </span>
                            <span><a href="">PF Return Filing</a> | </span>
                            <span><a href="">Eway Bill</a> | </span>
                            <span><a href="">GST Registration</a> | </span>
                            <span><a href="">TDS Filing</a> | </span>
                            <span><a href="">Udyam Registration</a> | </span>
                            <span><a href="">Trademark Registration</a> | </span>
                            <span><a href="">Startup India Registration</a> | </span>
                            <span><a href="">Professional Tax</a> | </span>
                            <span><a href="">Personal Tax Filing</a> | </span>
                            <span><a href="">Check Company or LLP name Availability</a> </span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopularSearch