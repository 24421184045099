import React from 'react';
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';
import './Privacy.css'
const Privacy = () => {
    return (
        <>
            <Navbar />

            <div style={{ padding: '20px', lineHeight: '1.6', fontFamily: 'Arial, sans-serif' }} class="container">
                <h1>Privacy Policy</h1>
                <p>Last updated: [Date]</p>

                <h2>Introduction</h2>
                <p>
                    We at Compliancekare are committed to safeguarding your privacy. This Privacy Policy outlines the practices
                    and policies related to the collection, use, storage, and disclosure of personal information that we receive from
                    users of our services, in accordance with the laws of India, including but not limited to the Information Technology
                    (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, and other
                    applicable data protection regulations.
                </p>

                <h2>Collection of Information</h2>
                <p>
                    We collect and process the following types of personal information:
                </p>
                <ul>
                    <li><strong>Personal Information:</strong> This includes your name, email address, phone number, and any other information that you provide to us voluntarily.</li>
                    <li><strong>Sensitive Personal Data or Information:</strong> In accordance with the IT Rules, this may include passwords, financial information (such as bank accounts, credit card details), health information, etc.</li>
                    <li><strong>Usage Data:</strong> Information automatically collected from your device, such as IP address, browser type, operating system, and usage patterns.</li>
                </ul>

                <h2>Use of Collected Information</h2>
                <p>
                    We use the information we collect for the following purposes:
                </p>
                <ul>
                    <li>To provide and maintain our services.</li>
                    <li>To notify you about changes to our services.</li>
                    <li>To allow you to participate in interactive features of our services.</li>
                    <li>To provide customer support.</li>
                    <li>To gather analysis or valuable information so that we can improve our services.</li>
                    <li>To monitor the usage of our services.</li>
                    <li>To detect, prevent, and address technical issues.</li>
                    <li>To comply with legal obligations under Indian law.</li>
                </ul>

                <h2>Disclosure of Information</h2>
                <p>
                    We may disclose your personal information in the following circumstances:
                </p>
                <ul>
                    <li>When required by law or in response to legal processes, such as a court order.</li>
                    <li>To law enforcement authorities, regulatory bodies, or other government officials.</li>
                    <li>To protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others.</li>
                </ul>

                <h2>Data Security</h2>
                <p>
                    We implement reasonable security practices and procedures, as mandated under the IT Rules, to protect your personal information.
                    However, please note that no method of transmission over the Internet, or method of electronic storage, is 100% secure.
                </p>

                <h2>Your Rights</h2>
                <p>
                    Under Indian law, you have the right to access and correct your personal information. If you would like to access or update your information, please contact us at info@compliancekare.com/+91 9625758585.
                </p>

                <h2>Data Retention</h2>
                <p>
                    We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected,
                    or as required under applicable laws.
                </p>

                <h2>Changes to This Privacy Policy</h2>
                <p>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have any questions about this Privacy Policy, please contact us at info@compliancekare.com/+91 9625758585.
                </p>
            </div>
            <Footer />

        </>

    );
}

export default Privacy;
