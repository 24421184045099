import React from 'react';
import { FaBalanceScale, FaGavel, FaBriefcase, FaFileSignature } from 'react-icons/fa'; // Importing some compliance-related icons

const Ourcompliances = () => {
  return (
    <div className="container my-5">
      <h2 className="text-center mb-5" style={{ color: '#105784' }}>Our Compliances</h2>
      <div className="row">
        {/* Compliance List with Icons */}
        {[
          {
            title: 'Regulatory Compliance',
            description:
              'We assist businesses in understanding and adhering to industry-specific regulations, ensuring that operations align with legal requirements.',
            icon: <FaBalanceScale size={50} color="#105784" />, // Icon for Regulatory Compliance
          },
          {
            title: 'Corporate Governance',
            description:
              'Strengthen your corporate structure with our expertise in establishing and maintaining effective governance practices.',
            icon: <FaGavel size={50} color="#105784" />, // Icon for Corporate Governance
          },
          {
            title: 'Employment Law Compliance',
            description:
              'Navigate the complexities of employment regulations, from drafting policies to handling disputes, with our comprehensive employment law compliance services.',
            icon: <FaBriefcase size={50} color="#105784" />, // Icon for Employment Law Compliance
          },
          {
            title: 'E-verify Income Tax Return',
            description:
              'To electronically verify your Income Tax Return (ITR) for the Financial Year 2023-24, you can use various methods approved by the Income Tax Department of India.',
            icon: <FaFileSignature size={50} color="#105784" />, // Icon for E-verify Income Tax Return
          },
        ].map((service, index) => (
          <div className="col-md-6 col-lg-3 mb-4" key={index}>
            <div
              className="service-box p-4 rounded text-center bg-white shadow-sm h-100"
              style={{
                transition: 'all 0.3s ease-in-out',
                border: '1px solid #ddd',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.1)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = 'none';
              }}
            >
              {/* Icon Section */}
              <div className="icon mb-3">{service.icon}</div>

              {/* Title Section */}
              <h5
                className="mb-3"
                style={{ fontWeight: '700', color: '#105784' }}
              >
                {service.title}
              </h5>

              {/* Description Section */}
              <p className="text-secondary" style={{ textAlign: 'justify' }}>
                {service.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ourcompliances;
