import React from 'react'
import { Link } from 'react-router-dom'
import TopHeader from '../Header/TopHeader'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import ReffaralForm from './ReffaralForm'
import PopularService from './PopularService'
import PopularSearch from './PopularSearch'

function Rewards() {
    return (
        <>
            <TopHeader />
            <Navbar />
            <section>
                <div className='container'>
                    <div className='p-4 container '>
                        <h1 class="text-center">Join our Rewarding Referral Program</h1>
                        <p style={{ textAlign: "justify" }}>
                            Join the IndiaFilings Referral Program by referring friends or family members who are looking to start a business or running a business. You can earn upto INR 2000 per successful referral subject to our referral<a href="">terms and conditions</a> .
                        </p>

                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <img src="https://www.indiafilings.com/images/referral.jpg" alt="fgfg" style={{ height: "400px" }} />
                            </div>
                            <div class="col">
                                <ReffaralForm />
                            </div>
                        </div>

                    </div>



                </div>
                



                <PopularService/>
                <PopularSearch/>
            </section>

          
          
            <Footer />
        </>
    )
}

export default Rewards