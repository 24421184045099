import React from 'react'
import Expert from '../Images/Experties.png'
import Expert1 from '../Images/Person.png'
import Expert2 from '../Images/Techonology.png'
import Expert3 from '../Images/Compliance Assurance.png'
import Expert4 from '../Images/images__8_-removebg-preview.png'
import Expert5 from '../Images/Risk.png'
import "../Css/Whychoose.css"

function WhyChooseUs() {
    return (
        <>
            <section className="we-offer-area text-center bg-gray p-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="site-heading text-center">
                                <h2>
                                Why Choose  <span>ComplianceKare</span>
                                </h2>

                            </div>
                        </div>
                    </div>
                    <div className="row our-offer-items less-carousel">
                        {/* Single Item */}
                        <div className="col-md-4 col-sm-6 equal-height">
                            <div className="item">
                               <img src={Expert} className='w-75'/>
                                <h4>Expertise</h4>
                                <p>
                                Our team comprises seasoned tax professionals certified and knowledgeable Experts with in-depth knowledge of tax laws, regulations, and a proven track record of delivering accurate and reliable financial services.
                                </p>
                            </div>
                        </div>
                        {/* End Single Item */}
                        {/* Single Item */}
                        <div className="col-md-4 col-sm-6 equal-height">
                            <div className="item">
                            <img src={Expert1} className='w-50'/>
                                <h4>Personalized Approach</h4>
                                <p>
                                We understand that every business is unique. That's why we take the time to understand your specific requirements and craft customized solutions to address your financial challenges.
                                </p>
                            </div>
                        </div>
                        {/* End Single Item */}
                        {/* Single Item */}
                        <div className="col-md-4 col-sm-6 equal-height">
                            <div className="item">
                            <img src={Expert2} className='w-100'/>
                                <h4>Technology Integration</h4>
                                <p>
                                Embracing cutting-edge accounting technologies, we ensure efficiency, accuracy, and security in handling your financial data.
                                </p>
                            </div>
                        </div>
                        {/* End Single Item */}
                        {/* Single Item */}
                        <div className="col-md-4 col-sm-6 equal-height">
                            <div className="item">
                            <img src={Expert3} className='w-50'/>
                                <h4>Compliance Assurance</h4>
                                <p>
                                Stay ahead of regulatory changes and tax obligations with our expert guidance, ensuring that your business remains in full compliance with applicable laws.
                                </p>
                            </div>
                        </div>
                        {/* End Single Item */}
                        {/* Single Item */}
                        <div className="col-md-4 col-sm-6 equal-height">
                            <div className="item">
                            <img src={Expert4} className='w-50'/>
                                <h4>Strategic Planning </h4>
                                <p>
                                Beyond compliance, we focus on strategic tax planning to help you make informed financial decisions and optimize your tax position.
                                </p>
                            </div>
                        </div>
                        {/* End Single Item */}
                        {/* Single Item */}
                        <div className="col-md-4 col-sm-6 equal-height">
                            <div className="item">
                            <img src={Expert5} className='w-50'/>
                                <h4>Risk Mitigation </h4>
                                <p>
                                We focus on proactive risk management, helping businesses identify and address potential compliance issues before they escalate.
                                </p>
                            </div>
                        </div>
                        {/* End Single Item */}
                    </div>
                </div>
            </section>

        </>
    )
}

export default WhyChooseUs