import React, { useState, useEffect } from 'react'
import axios from 'axios';

function IncomeTaxReturn() {
    const [cardimg, setcardimgs] = useState([])

    const card1 = async () => {
        const { data } = await axios.post('https://api.compliancekare.com/api/getPackage');
        setcardimgs(data);
    }
    useEffect(() => {
        card1();
    }, []);
    return (
        <>
            <div class="container pt-2">
            <h1 style={{ textAlign: "center", fontSize: "1.6rem",fontFamily:"poppins" }} class="p-4"><b>Easiest Way of e-filing your Income Tax Return in India</b></h1>
                <div class="row">
                    {cardimg?.map((item, index) => {
                      
                        return (

                            <div class="col-md-4">
                                <div class="card" >
                                    <img src={item.packageImage} class="card-img-top " alt="..." style={{height:"200px"}} />
                                    <div class="card-body">
                                        <h5 class="card-title text-center " style={{fontFamily:"poppins",fontSize:"23px",fontWeight:"600"}}>{item.packageName}</h5>
                                        <p class="card-text " style={{fontFamily:"poppins",fontSize:"18px",textAlign:"justify"}}>{item.packageDescription}</p>
                                        <a href="#" class="btn btn-primary">File ITR Now</a>
                                    </div>
                                </div>
                            </div>

                        )
                    })}

                </div>
            </div>

        </>
    )
}

export default IncomeTaxReturn