import React, { useState, useEffect, useRef } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import axios from 'axios';
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';
import './UserDashboard.css'
import Swal from 'sweetalert2'; // Make sure SweetAlert2 is imported
import './UserDashboard.css'
import { v4 as uuidv4 } from 'uuid';
const UserDashboard = (userId) => {
  const [image, setImage] = useState('../assets/images/avtar/1.jpg');
  const [preview, setPreview] = useState(image);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);;
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [weeklytransactions, setWeeklytransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const modalRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [serviceId, setServiceId] = useState(null);
  const token = sessionStorage.getItem('token');
  const [servicePurchased, setServicePurchased] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('pending'); // 'pending', 'success', or 'failed'
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [multiple, setMultiple] = useState(false);
  const [allServiceIds, setAllServiceIds] = useState([]);
  const [fileData, setFileData] = useState({}); // Initialize fileData state
  const [refundMessage, setRefundMessage] = useState('');
  const [status, setStatus] = useState('');
  const [cancellationInfo, setCancellationInfo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [user, setUser] = useState({
    name: '',
    mobileNumber: '',
    email: '',
    address: '',
    cityState: '',
    district: '',
    zipCode: ''
  });

  const [showModal, setShowModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelClick = (transaction) => {
    setSelectedTransaction(transaction); // Set the selected transaction
    setShowModal(true); // Show the modal
  };

  // Handle confirmation of cancellation
  const handleConfirmCancel = async () => {
    if (!selectedTransaction) return;

    setIsLoading(true); // Set loading state to true
    setErrorMessage(''); // Reset any previous error message

    try {
      const response = await axios.post(
        'https://api.compliancekare.com/api/OrderApi/CancelOrder',
        { id: selectedTransaction.id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      console.log('Transaction canceled:', response.data);

      // Update the transaction status in state after successful cancellation
      const updatedTransactions = transactions.map((transaction) =>
        transaction.id === selectedTransaction.id
          ? { ...transaction, cancelStatusName: 'Cancelled' } // Update the cancel status
          : transaction
      );
      setTransactions(updatedTransactions); // Update the state with new data

      setShowModal(false); // Close the modal
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
      console.error('Error canceling transaction:', error);
    } finally {
      setIsLoading(false); // Set loading state back to false
    }
  };



  const [userData, setUserData] = useState();

  // Handle image change
  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Uploaded Documents:', uploadedDocuments);
    alert('Your documents have been submitted successfully!');
  };

  // Fetch user details
  useEffect(() => {
    // Fetch user details
    const fetchUserDetails = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get('https://api.compliancekare.com/api/Compliance/GetUserDetail', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.succeeded) {
          setUser({
            ...response.data.data,
            profileImage: response.data.data.profileImage
              ? `https://api.compliancekare.com/Images/${response.data.data.profileImage}`
              : null, // Set null if no image is available
          });
        } else {
          console.error('Error fetching user details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    // Simulating an API call to fetch dashboard data
    const fetchDashboardData = async () => {
      // Example API call (replace with real API call)
      // const response = await fetch(`/api/dashboard/${userId}`);
      // const data = await response.json();

      // For demo, using hardcoded data
      const data = {
        refundMessage: 'The amount will be refunded within 7 working days',
        status: 'Active',
        cancellationInfo: 'Your Cancellation id SGH4152.',
      };

      setRefundMessage(data.refundMessage);
      setStatus(data.status);
      setCancellationInfo(data.cancellationInfo);
    };

    fetchDashboardData();
  }, []);


  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setSelectedStateId(stateId)
  }
  const handleDistrictChange = (event) => {
    const districtId = event.target.value;
    setSelectedStateId(districtId)
  }
  // Fetch districts based on selected state
  useEffect(() => {
    if (user) {
      setSelectedStateId(user.stateId);  // Assuming user.stateId contains the state's ID
      setSelectedDistrictId(user.districtId);  // Assuming user.districtId contains the district's ID
    }
  }, [user]);

  // Fetch districts whenever the state is selected or on component mount if the user already has a state
  useEffect(() => {
    if (selectedStateId) {
      axios
        .get(`https://api.compliancekare.com/api/CommonApi/GetDistrictByState?StateId=${selectedStateId}`)
        .then((response) => {
          if (response.data.status === 200) {
            setDistricts(response.data.data);
          }
        })
        .catch((error) => console.error("Error fetching districts:", error));
    }
  }, [selectedStateId]);



  // Fetch states on component mount 
  useEffect(() => {
    axios.get('https://api.compliancekare.com/api/CommonApi/GetStates')
      .then(response => {
        if (response.data.status === 200) {
          setStates(response.data.data);
        }
      })
      .catch(error => console.error('Error fetching states:', error));
  }, []);


  const handleModalSubmit = () => {
    // Update user state with modal changes here
    console.log('Updated User:', user);
  };

  const validateUserData = (data) => {
    // Example validation checks
    if (!data.userName) return 'Name is required.';
    if (!data.mobileNo) return 'Phone number is required.';
    if (!data.address) return 'Address is required.';
    if (!data.pincode) return 'ZIP code is required.';
    if (!data.stateId) return 'State is required.';
    if (!data.districtId) return 'District is required.';
    // Add more validations as needed
    return null;
  };


  const handleSaveChanges = async () => {
    const updatedUser = {
      userName: document.getElementById('editName').value,
      mobileNo: document.getElementById('editPhone').value,
      address: document.getElementById('editAddress').value,
      pincode: document.getElementById('editZip').value,
      stateId: selectedStateId,
      districtId: selectedDistrictId,
    };

    try {
      const token = sessionStorage.getItem('token');
      await axios.post('https://api.compliancekare.com/api/Compliance/UpdateUserProfile', updatedUser, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      Swal.fire({
        icon: 'success',
        title: 'Profile Updated',
        text: 'Your profile has been updated successfully.',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          // Update user data in the UI without refreshing
          setUser({
            ...user,
            name: updatedUser.userName,
            mobileNumber: updatedUser.mobileNo,
            address: updatedUser.address,
            pincode: updatedUser.pincode,
            stateName: states.find(state => state.id === selectedStateId)?.stateName || user.stateName,
            districtName: districts.find(district => district.id === selectedDistrictId)?.districtName || user.districtName,
          });

          // Close the modal
          const modalElement = modalRef.current;
          if (modalElement) {
            const modal = new window.bootstrap.Modal(modalElement);
            modal.hide();
          }
        }
      });
    } catch (error) {
      console.error('Error updating profile', error);
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'Failed to update profile',
        confirmButtonText: 'OK',
      });
    }

    if (selectedImage) {
      try {
        const token = sessionStorage.getItem('token');
        const formData = new FormData();
        formData.append('ProfileImage', selectedImage);
        formData.append('ProfileImagePath', user?.profileImage);

        const response = await axios.post('https://api.compliancekare.com/api/Compliance/UpdateUserProfilePic', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.succeeded) {
          // Assuming the response includes the updated image URL or path
          const updatedImagePath = `https://api.compliancekare.com/Images/${response.data.data.profileImage}`;
          setUser((prevUser) => ({
            ...prevUser,
            profileImage: updatedImagePath
          }));


          console.log('Profile image updated successfully.');
        } else {
          console.error('Error updating profile image:', response.data.message);
        }
      } catch (error) {
        console.error('Error updating profile image:', error);
      }
    }

  };


  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('https://api.compliancekare.com/api/OrderApi/TransactionHistory', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const transactionData = response.data.data;
        const serviceIds = transactionData.map(transaction => transaction.serviceId);

        setServicePurchased(transactionData.length > 0);
        setTransactions(transactionData);
        setAllServiceIds(serviceIds); // Store all serviceIds in state
        setLoading(false);
        console.log("All Service IDs:", serviceIds); // Debugging: log all serviceIds
      } catch (error) {
        const generatedTransactionId = uuidv4(); // Generate a unique ID for placeholder
        const errorMessage = error.response?.data?.error?.description || 'Error fetching transaction history';
        setError(errorMessage);

        setTransactions([{
          transactionId: generatedTransactionId,
          orderDate: new Date().toISOString(),
          amount: 0,
          paymentStatus: 'failed',
        }]);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [token]);



  useEffect(() => {
    const fetchWeeklyTransactions = async () => {
      try {
        const response = await axios.get('https://api.compliancekare.com/api/OrderApi/WeeklyTransactionHistory', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const transactionData = response.data.data;
        const serviceIds = transactionData.map(transaction => transaction.serviceId);

        setServicePurchased(transactionData.length > 0);
        setWeeklytransactions(transactionData);
        console.log("All Transactions:", transactionData); // Debugging: log transactions
      } catch (error) {
        const generatedTransactionId = uuidv4(); // Generate a unique ID for placeholder
        const errorMessage = error.response?.data?.error?.description || 'Error fetching transaction history';
        setError(errorMessage);

        setWeeklytransactions([{
          transactionId: generatedTransactionId,
          orderDate: new Date().toISOString(),
          amount: 0,
          paymentStatus: 'failed',
        }]);
      }
    };

    fetchWeeklyTransactions();
  }, [token]);


  if (loading) {
    return <p>Loading transaction history...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleFileChange = (event, transactionId) => {
    const files = event.target.files;
    setFileData(prevState => ({
      ...prevState,
      [transactionId]: files, // Map files to transactionId
    }));
  };


  const handleFileUpload = async (transactionId) => {
    console.log('Uploading files for transactionId:', transactionId);
    console.log('Files:', fileData[transactionId]);

    const files = fileData[transactionId];
    if (!files || files.length === 0) {
      setError('No files selected.');
      return;
    }

    if (!servicePurchased) {
      setError('Service not purchased. Please buy the service before uploading documents.');
      return;
    }

    const transaction = transactions.find(t => t.transactionId === transactionId);
    if (!transaction) {
      setError('Transaction not found.');
      return;
    }

    console.log('ServiceId:', transaction.serviceId);

    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('Documents', file);
    });
    formData.append('ServiceId', transaction.serviceId); // Ensure the correct serviceId is used

    try {
      const response = await axios.post(
        'https://api.compliancekare.com/api/Compliance/UploadDocuments',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      alert(response.data.message || 'Documents uploaded successfully!');
    } catch (error) {
      setError(error.response?.data?.message || 'Error uploading documents.');
    }
  };


  function myFunction() {
    var x = document.getElementById("myFile").multiple;
    document.getElementById("demo").innerHTML = x;
  }



  return (
    <div className="dashboard-container">
      <Navbar />

      <>
        <section
          className="small-section dashboard-section bg-inner"
          data-sticky_parent=""
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <div className="pro_sticky_info" data-sticky_column="">
                  <div className="dashboard-sidebar">
                    <div className="profile-top">
                      <div className="profile-image">
                        {user && (
                          <div className="profile-img-container">
                            {user.profileImage ? (
                              <img src={user.profileImage} alt="Profile" className="profile-img" />
                            ) : (
                              <FaUserCircle size={100} className="default-profile-icon" />
                            )}
                          </div>
                        )}
                        <a
                          className="profile-edit"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-profile-image-modal"
                          href="javascript:void(0)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-edit"
                          >
                            <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                            <polygon points="18 2 22 6 12 16 8 16 8 12 18 2" />
                          </svg>
                        </a>
                      </div>


                      {/* Edit Profile Image Modal */}
                      <div
                        className="modal fade"
                        id="edit-profile-image-modal"
                        tabIndex="-1"
                        aria-labelledby="editProfileImageModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="editProfileImageModalLabel">
                                Edit Profile Image
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <form>
                                <div className="mb-3">
                                  <label htmlFor="profileImageUpload" className="form-label">
                                    Choose a new profile image
                                  </label>
                                  <input
                                    className="form-control"
                                    type="file"
                                    id="profileImageUpload"
                                    onChange={handleImageChange}
                                  />
                                </div>
                                <div className="mb-3">
                                  {user && (
                                    <div className="profile-img-container-modal">
                                      {user.profileImage ? (
                                        <img
                                          src={user.profileImage}
                                          alt="Profile"
                                          className="img-fluid profile-img"
                                        />
                                      ) : (
                                        <FaUserCircle size={100} className="default-profile-icon-modal" /> // Fallback icon
                                      )}
                                    </div>
                                  )}
                                </div>

                              </form>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSaveChanges}
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="profile-detail">
                        <h5>{user.name}</h5>
                        <h6>{user.mobileNumber}</h6>
                        <h6>{user.email}</h6>
                      </div>
                    </div>
                    <div className="faq-tab">
                      <ul className="nav nav-tabs" id="top-tab" role="tablist">
                        <li className="nav-item">
                          <a
                            data-bs-toggle="tab"
                            className="nav-link active"
                            href="#dashboard"
                          >
                            dashboard
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            data-bs-toggle="tab"
                            className="nav-link"
                            href="#profile"
                          >
                            profile
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            data-bs-toggle="tab"
                            className="nav-link"
                            href="#Service"
                          >
                            Service Status
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="edit-image"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="editImageLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="editImageLabel">Edit Profile Image</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="form-group">
                          <label htmlFor="uploadImage">Choose New Image</label>
                          <input
                            type="file"
                            className="form-control"
                            id="uploadImage"
                            accept="image/*"
                          />
                          <small className="form-text text-muted">
                            Supported formats: jpg, jpeg, png
                          </small>
                        </div>
                        <div className="form-group mt-3">
                          <img
                            id="previewImage"
                            src="../assets/images/avtar/1.jpg"
                            className="img-fluid"
                            alt="Profile Preview"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-10">
                <div className="product_img_scroll" data-sticky_column="">
                  <div className="tab-content" id="top-tabContent">

                    {/* Dashboard Section */}
                    <div className="tab-pane fade show active" id="dashboard">
                      <div className="container-fluid p-4 rounded">

                        {/* Dashboard Introduction Card */}
                        <div className="card mb-4">
                          <div className="card-body">
                            <h5 className="card-title">Welcome to Your Compliance Dashboard! <span>Compliancekare</span></h5>
                            <p className="card-text">
                              Welcome to the Compliance Dashboards section, where you can seamlessly monitor, manage, and analyze all aspects of compliance within your organization.
                            </p>
                          </div>
                        </div>

                        {/* Transaction History Card */}
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">Transaction History</h4>
                          </div>
                          <div className="card-body">
                            {loading ? (
                              <p>Loading transaction history...</p>
                            ) : error ? (
                              <div>
                                <p>{error}</p>
                                {error.includes('declined by the bank') && (
                                  <p>Your payment was declined. Please try another payment method or contact your bank for more information.</p>
                                )}
                              </div>
                            ) : (
                              <div className="transaction-history-list">
                                <div className="table-responsive">
                                  <table className="table table-striped table-bordered text-center">
                                    <thead className="table-dark">
                                      <tr>
                                        <th className="table-col-date">Date</th>
                                        <th className="table-col-service">Service Name</th>
                                        <th className="table-col-transaction">Transaction ID</th>
                                        <th className="table-col-amount">Amount</th>
                                        <th className="table-col-payment-status">Payment Status</th>
                                        <th className="table-col-status">Status</th>
                                        <th className="table-col-file-upload">File Upload</th>
                                        <th className="table-col-submit-docs">Submit Documents</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {transactions.length > 0 ? (
                                        transactions.map((transaction) => (
                                          <tr key={transaction.id}>
                                            <td>{new Date(transaction.orderDate).toLocaleDateString()}</td>
                                            <td>{transaction.serviceName}</td>
                                            <td>{transaction.transactionId}</td>
                                            <td>{transaction.amount}</td>
                                            <td>{transaction.paymentStatus === 'success' ? 'Paid' : 'Pending'}</td>
                                            <td>
                                              <span className={`badge bg-${transaction.paymentStatus === 'success' ? 'success' : 'warning'}`}>
                                                {transaction.paymentStatus}
                                              </span>
                                            </td>
                                            <td className="table-col-file-upload">
                                              <div className="file-upload-container">
                                                <input
                                                  type="file"
                                                  id={`fileInput-${transaction.transactionId}`}
                                                  onChange={(event) => handleFileChange(event, transaction.transactionId)}
                                                  name="documents"
                                                  multiple
                                                  className="form-control-file"
                                                  disabled={transaction.paymentStatus === 'failed'} // Disable if payment failed
                                                />
                                              </div>

                                            </td>

                                            <td>
                                              <div className="submit-button-container">
                                                <button
                                                  className="btn btn-primary"
                                                  onClick={() => handleFileUpload(transaction.transactionId)}
                                                  disabled={transaction.paymentStatus === 'failed'} // Disable button if payment failed
                                                >
                                                  Submit
                                                </button>
                                              </div>

                                            </td>

                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan="8">No transactions found.</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                            )}
                            {uploadStatus && <p>{uploadStatus}</p>}
                          </div>


                        </div>
                      </div>
                    </div>

                    {/* Profile Section */}
                    <div className="tab-pane fade" id="profile">
                      <div className="container-fluid p-4">
                        <div className="card border-light shadow-sm rounded-lg">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                              <h4 className="card-title mb-0">User Profile</h4>
                              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#edit-profile">
                                <i className="bi bi-pencil"></i> Edit
                              </button>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <h6 className="text-muted">Name:</h6>
                                  <p className="mb-0">{user.name}</p>
                                </div>
                                <div className="mb-3">
                                  <h6 className="text-muted">Phone:</h6>
                                  <p className="mb-0">{user.mobileNumber}</p>
                                </div>
                                <div className="mb-3">
                                  <h6 className="text-muted">Email:</h6>
                                  <p className="mb-0">{user.email}</p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <h6 className="text-muted">Address:</h6>
                                  <p className="mb-0">{user.address}</p>
                                </div>
                                <div className="mb-3">
                                  <h6 className="text-muted">State:</h6>
                                  <p className="mb-0">{user.stateName}</p>
                                </div>
                                <div className="mb-3">
                                  <h6 className="text-muted">District:</h6>
                                  <p className="mb-0">{user.districtName}</p>
                                </div>
                                <div className="mb-3">
                                  <h6 className="text-muted">ZIP Code:</h6>
                                  <p className="mb-0">{user.pincode}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Modal for Editing User Profile */}
                        <div className="modal fade" id="edit-profile" tabIndex="-1" aria-labelledby="editProfileLabel" aria-hidden="true" ref={modalRef}>
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="editProfileLabel">Edit User Profile</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                <form>
                                  <div className="mb-3">
                                    <label htmlFor="editName" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="editName" defaultValue={user.name} />
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="editPhone" className="form-label">Phone</label>
                                    <input type="tel" className="form-control" id="editPhone" defaultValue={user.mobileNumber} />
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="editEmail" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="editEmail" defaultValue={user.email} />
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="editAddress" className="form-label">Address</label>
                                    <input type="text" className="form-control" id="editAddress" defaultValue={user.address} />
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="states" className="form-label">State</label>
                                    <select id="states" className="form-select" onChange={(e) => setSelectedStateId(e.target.value)} value={selectedStateId || ''}>
                                      <option value="">Select a state</option>
                                      {states.map(state => (
                                        <option key={state.id} value={state.id}>{state.stateName}</option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="districts" className="form-label">District</label>
                                    <select id="districts" className="form-select" value={selectedDistrictId || ''} onChange={(e) => setSelectedDistrictId(e.target.value)}>
                                      <option value="">Select a district</option>
                                      {districts.map(district => (
                                        <option key={district.id} value={district.id}>{district.districtName}</option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="editZip" className="form-label">ZIP Code</label>
                                    <input type="text" className="form-control" id="editZip" defaultValue={user.pincode} />
                                  </div>
                                </form>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Save changes</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="Service">
                      <div className="container-fluid p-4">
                        <div className="card border-light shadow-sm rounded-lg">
                          <div className="card-body">
                            <h2 className="mb-4">Service Details</h2>

                            <div className="row">

                              <div className="col-md-4 mb-3">
                                <div className="status-section p-4 border rounded bg-light">
                                  <h4 className="mb-3">Refund</h4>
                                  {refundMessage ? (
                                    <p>{refundMessage}</p>
                                  ) : (
                                    <p>No refund information available.</p>
                                  )}
                                </div>
                              </div>


                              <div className="col-md-4 mb-3">
                                <div className="status-section p-4 border rounded bg-light">
                                  <h4 className="mb-3">Status</h4>
                                  {status ? (
                                    <p>{status}</p>
                                  ) : (
                                    <p>Status information unavailable.</p>
                                  )}
                                </div>
                              </div>


                              <div className="col-md-4 mb-3">
                                <div className="cancellation-section p-4 border rounded bg-light">
                                  <h4 className="mb-3">Cancellation </h4>
                                  {cancellationInfo ? (
                                    <p>{cancellationInfo}</p>
                                  ) : (
                                    <p>No recent cancellations.</p>
                                  )}
                                </div>
                              </div>
                            </div>


                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Service Status</h4>
                              </div>
                              <div className="card-body">
                                {loading ? (
                                  <p>Loading transaction history...</p>
                                ) : error ? (
                                  <div>
                                    <p>{error}</p>
                                    {error.includes('declined by the bank') && (
                                      <p>Your payment was declined. Please try another payment method or contact your bank for more information.</p>
                                    )}
                                  </div>
                                ) : (
                                  <div className="transaction-history-list">
                                    <div className="table-responsive">
                                      <table className="table table-striped table-bordered text-center">
                                        <thead className="table-dark">
                                          <tr>
                                            <th className="table-col-date">Date</th>
                                            <th className="table-col-service">Service Name</th>
                                            <th className="table-col-amount">Amount</th>
                                            <th className="table-col-cancel">Cancel Request</th>
                                            <th className="table-col-payment-status">Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {weeklytransactions.length > 0 ? (
                                            weeklytransactions.map((transaction) => (
                                              <tr key={transaction.id}>
                                                <td>{new Date(transaction.orderDate).toLocaleDateString()}</td>
                                                <td>{transaction.serviceName}</td>
                                                <td>{transaction.amount}</td>
                                                <td>
                                                  <button
                                                    className="btn btn-primary"
                                                    onClick={() => handleCancelClick(transaction)} // Pass transaction object on click
                                                  >
                                                    Cancel
                                                  </button>
                                                </td>
                                                <td>
                                                  <span
                                                    className={`badge ${transaction.cancelStatusName === 'Cancelled'
                                                      ? 'bg-danger'
                                                      : transaction.cancelStatusName === 'Requested'
                                                        ? 'bg-primary'
                                                        : transaction.cancelStatusName === 'Refunded'
                                                          ? 'bg-success'
                                                          : 'bg-warning' // Default color if none of the above match
                                                      }`}
                                                  >
                                                    {transaction.cancelStatusName}
                                                  </span>

                                                </td>
                                              </tr>
                                            ))
                                          ) : (
                                            <tr>
                                              <td colSpan="8">No transactions found.</td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>

                                    {/* Modal */}
                                    {showModal && (
                                      <div className="modal fade show d-block" role="dialog" tabIndex="-1">
                                        <div className="modal-dialog" role="document">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5 className="modal-title">Cancel Transaction</h5>
                                              <button
                                                type="button"
                                                className="btn-close"
                                                aria-label="Close"
                                                onClick={() => setShowModal(false)}
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              {errorMessage ? (
                                                <div className="alert alert-danger">{errorMessage}</div>
                                              ) : (
                                                <p>Are you sure you want to cancel this service?</p>
                                              )}
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => setShowModal(false)}
                                              >
                                                No
                                              </button>
                                              {!errorMessage && (
                                                <button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  onClick={handleConfirmCancel}
                                                  disabled={isLoading} // Disable button while loading
                                                >
                                                  {isLoading ? 'Processing...' : 'Yes'}
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    )}
                                  </div>

                                )}
                                {uploadStatus && <p>{uploadStatus}</p>}
                              </div>


                            </div>

                            <div className="additional-info mt-4">
                              {/* Additional Information Section */}
                              <h3 className="mb-3">Additional Information</h3>
                              <p>
                                For further details regarding our services, including special conditions and terms, please contact our support team.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </>
      <Footer />
    </div>
  );
};

export default UserDashboard;
