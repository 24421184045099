import React from 'react';
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';
import './Blog.css'
const Blog = () => {
    const blogs = [
        {
            id: 1,
            title: 'Exploring the Beauty of Nature',
            date: 'August 28, 2024',
            author: 'John Doe',
            excerpt: 'Discover the wonders of the natural world with our in-depth guide to the most beautiful destinations on Earth...',
            image: 'https://via.placeholder.com/400x200',
        },
        {
            id: 2,
            title: 'Top 10 Travel Destinations for 2024',
            date: 'August 20, 2024',
            author: 'Jane Smith',
            excerpt: 'Looking for your next adventure? Check out our list of the top 10 must-visit travel destinations for 2024...',
            image: 'https://via.placeholder.com/400x200',
        },
        {
            id: 3,
            title: 'How to Plan a Perfect Weekend Getaway',
            date: 'August 15, 2024',
            author: 'David Lee',
            excerpt: 'Planning a weekend getaway? Here are our top tips to ensure you have a relaxing and enjoyable short trip...',
            image: 'https://via.placeholder.com/400x200',
        },
    ];

    return (

        <>
            <Navbar />


            <h2 style={{ color: "black", textAlign: "center", fontFamily: "poppins" }}>Blogs</h2>
            <p style={{ color: "black", textAlign: "center", color: "#30909d", fontFamily: "poppins" }}>Filing your income tax return electronically has become increasingly streamlined in India, with user-friendly platforms designed to simplify the process</p>
            <div className="blog-section">
                {blogs.map((blog) => (
                    <div key={blog.id} className="blog-card">
                        <img src={blog.image} alt={blog.title} className="blog-image" />
                        <div className="blog-content">
                            <h2>{blog.title}</h2>
                            <p>{blog.excerpt}</p>
                            <p className="blog-meta">
                                <span>By {blog.author}</span> | <span>{blog.date}</span>
                            </p>
                        </div>
                    </div>
                ))}

            </div>
            <Footer />

        </>

    );
};

export default Blog;
