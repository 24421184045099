import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import '../Css/Custom.css';
import Logo from '../Images/CK-Logo-removebg-preview-removebg-preview.png';
import './Navbar.css';

function Navbar() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [servicesByType, setServicesByType] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
            fetchUserData(token);
        }
        fetchServiceTypesAndServices();
    }, []);

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get('https://api.compliancekare.com/api/Compliance/GetUserDetail', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.data.succeeded) {
                setUserData(response.data.data);
            } else {
                console.warn('User data not found.');
                setUserData(null);
            }
        } catch (err) {
            if (err.response && err.response.status === 404) {
                console.warn('User not found.');
            } else {
                console.error('Error fetching user data:', err);
            }
            setUserData(null);
        }
    };

    const fetchServiceTypesAndServices = async () => {
        try {
            const serviceTypesResponse = await axios.get(
                'https://api.compliancekare.com/api/Compliance/GetServiceTypes'
            );
    
            if (serviceTypesResponse.data.succeeded) {
                const types = serviceTypesResponse.data.data;
                setServiceTypes(types);
    
                // Save serviceTypeId in localStorage
                types.forEach(type => {
                    localStorage.setItem(`serviceTypeId-${type.id}`, type.id);
                });
    
                // Fetch services for each service type
                const servicesPromises = types.map((type) =>
                    axios.get(
                        `https://api.compliancekare.com/api/Compliance/ServicesByTypeId?ServiceTypeId=${type.id}`
                    )
                );
    
                const servicesResponses = await Promise.all(servicesPromises);
    
                const servicesData = {};
                servicesResponses.forEach((res, index) => {
                    if (res.data.succeeded) {
                        servicesData[types[index].id] = res.data.data;
                    } else {
                        servicesData[types[index].id] = [];
                    }
                });
    
                setServicesByType(servicesData);
                setLoading(false);
            }
        } catch (err) {
            console.error('Error fetching service types and services:', err);
            setError('Failed to load services. Please try again later.');
            setLoading(false);
        }
    };
    
    

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        setIsLoggedIn(false);
        setUserData(null);
        navigate('/'); // Redirect to home page after logout
    };

    if (loading) {
        return (
            <div className="text-center my-5">
                <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="alert alert-danger text-center my-5" role="alert">
                {error}
            </div>
        );
    }

    return (
        <nav
            className="navbar navbar-expand-lg navbar-light bg-light sticky-top"
            style={{ borderBottom: '1px solid #89c764' }}
        >
            <div className="container-fluid">
                <Link to="/" className="navbar-brand">
                    <img src={Logo} alt="logo" className="LogoImage" title="COMPLIANCEKARE" />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link to="/" className="nav-link">
                                HOME
                            </Link>
                        </li>

                        {serviceTypes.map((serviceType) => (
                            <li className="nav-item dropdown" key={serviceType.id}>
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id={`navbarDropdown-${serviceType.id}`}
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {serviceType.serviceTypeName.toUpperCase()}
                                </a>
                                <ul className="dropdown-menu" aria-labelledby={`navbarDropdown-${serviceType.id}`}>
                                    {servicesByType[serviceType.id] && servicesByType[serviceType.id].length > 0 ? (
                                        servicesByType[serviceType.id].map((service) => (
                                            <li key={service.id}>
                                                {/* Pass serviceTypeId and serviceId in the URL */}
                                                <Link
                                                    to={`/services/${service.id}?serviceTypeId=${serviceType.id}`}
                                                    className="dropdown-item"
                                                >
                                                    {service.serviceName}
                                                </Link>
                                            </li>
                                        ))
                                    ) : (
                                        <li>
                                            <span className="dropdown-item text-muted">No services available</span>
                                        </li>
                                    )}
                                </ul>

                            </li>
                        ))}

                        <li className="nav-item">
                            <Link to="/contact" className="nav-link">
                                CONTACT US
                            </Link>
                        </li>

                        {isLoggedIn && userData ? (
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="userDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {userData.name}
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">

                                    <Link to="/UserDashboard">
                                        <button className="dropdown-item" >
                                            User Dashboard
                                        </button>
                                    </Link>

                                    <li>
                                        <button className="dropdown-item" onClick={handleLogout}>
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            <li className="nav-item">
                                <Link to="/login" className="nav-link">
                                    <button
                                        type="button"
                                        className="btn btn-outline-success"
                                        style={{ border: '1px solid green', color: 'green' }}
                                    >
                                        LOGIN
                                    </button>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
