import React from 'react';
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';

const Termscondition = () => {
    const containerStyle = {
        padding: '40px 20px',
        maxWidth: '1209px',
        margin: '32px auto',
        lineHeight: '1.8',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    };

    const titleStyle = {
        fontSize: '2em',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#2c3e50',
    };

    const sectionTitleStyle = {
        fontSize: '1.5em',
        marginBottom: '15px',
        color: '#34495e',
        borderBottom: '2px solid #ddd',
        paddingBottom: '5px',
    };

    const listStyle = {
        marginLeft: '20px',
        marginBottom: '20px',
    };

    const paragraphStyle = {
        marginBottom: '20px',
        textAlign: 'justify',
    };

    return (
        <>
            <Navbar />

            <div style={containerStyle}>
                <h1 style={titleStyle}>Terms and Conditions</h1>

                <h2 style={sectionTitleStyle}>Introduction</h2>
                <p style={paragraphStyle}>
                    These terms and conditions outline the rules and regulations for the use of Compliancekare's Website, located at <a href="https://compliancekare.com/">https://compliancekare.com/</a>.
                </p>

                <h2 style={sectionTitleStyle}>Acceptance of Terms</h2>
                <p style={paragraphStyle}>
                    By accessing this website, we assume you accept these terms and conditions. Do not continue to use Compliancekare.com if you do not agree to all of the terms and conditions stated on this page.
                </p>

                <h2 style={sectionTitleStyle}>License</h2>
                <p style={paragraphStyle}>
                    Unless otherwise stated, Compliancekare and/or its licensors own the intellectual property rights for all material on Compliancekare.com. All intellectual property rights are reserved. You may access this from Compliancekare.com for your own personal use subject to restrictions set in these terms and conditions.
                </p>

                <h2 style={sectionTitleStyle}>User Obligations</h2>
                <p style={paragraphStyle}>
                    As a user of this website, you agree to:
                </p>
                <ul style={listStyle}>
                    <li>Use the website in accordance with all applicable laws and regulations.</li>
                    <li>Not engage in any activity that disrupts or interferes with the website.</li>
                    <li>Provide accurate and up-to-date information when required.</li>
                </ul>

                <h2 style={sectionTitleStyle}>Limitation of Liability</h2>
                <p style={paragraphStyle}>
                    Compliancekare shall not be held liable for any damages arising out of or in connection with your use of the website. This includes, but is not limited to, direct, indirect, incidental, punitive, and consequential damages.
                </p>

                <h2 style={sectionTitleStyle}>Governing Law</h2>
                <p style={paragraphStyle}>
                    These terms and conditions are governed by and construed in accordance with the laws of India, and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                </p>

                <h2 style={sectionTitleStyle}>Changes to Terms and Conditions</h2>
                <p style={paragraphStyle}>
                    We reserve the right to amend these terms and conditions at any time. It is your responsibility to review these terms regularly to ensure you are aware of any changes.
                </p>

                <h2 style={sectionTitleStyle}>Contact Us</h2>
                <p style={paragraphStyle}>
                    If you have any questions about these terms, please contact us at <a href="mailto:info@compliancekare.com">info@compliancekare.com</a> or call us at +91 9625758585.
                </p>
            </div>

            <Footer />
        </>
    );
}

export default Termscondition;
