import React from 'react'
import { Link } from 'react-router-dom'
import "../Css/About.css"
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'

function About() {
    return (
        <>
            <Navbar />
            <section className="about-section">
                <div className="container">
                    <div className="row">
                        <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <span className="title">Welcome to ComplianceKare</span>
                                    <h2>Who We Are</h2>
                                </div>
                                <div className="text">
                                    ComplianceKare.com is a leading Tax, Account, and Legal service firm with a commitment to delivering expert guidance, personalized and comprehensive financial solutions. With a team of highly skilled and experienced professionals, we pride ourselves on our unwavering dedication to client satisfaction.
                                </div>
                                <div className="text">

                                    ComplianceKare is a comprehensive compliance management solution that focuses on streamlining and enhancing the compliance processes within an organization. This platform is designed to help businesses meet regulatory requirements, manage risks, and ensure adherence to industry standards. The following paragraphs provide an overview of ComplianceKare's key features and benefits.
                                </div>
                                <div className="text">

                                ComplianceKare offers a centralized and automated approach to compliance management, bringing together various facets of regulatory adherence into a cohesive system. One of its core strengths lies in its ability to simplify the complexity of compliance by providing a unified dashboard where users can monitor, track, and manage compliance-related activities in real-time.
                                </div>
                                <div className="btn-box">
                                    <Link to="/contact" className="theme-btn btn-style-one">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* Image Column */}
                        <div className="image-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column wow fadeInLeft">
                                
                                <figure className="image-1">
                                    <a href="#" className="lightbox-image" data-fancybox="images">
                                        <img
                                            title="uhkuk"
                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREGI3wRc202IccrxujRY5QK23KEeZVxeSAqg&usqp=CAU"
                                            alt=""
                                        />
                                    </a>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="sec-title">
                        <span className="title">Our Mission</span>
                        <h3>Pioneering Excellence in Regulatory Compliance  &amp; Management</h3>
                    </div>
                    <div className="text">
                    At ComplianceKare.com, our mission is to empower individuals and businesses by providing exceptional Tax, Accounting, and Legal services with the knowledge and resources needed to navigate the complex landscape of tax and Legal Compliances successfully. We are committed to delivering unparalleled tax, Account & Legal services that contribute to your financial success. We strive to be your trusted financial partner, contributing to your long-term success.
                    </div>
                    
                </div>
            </section>
            <Footer/>

        </>
    )
}

export default About