import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import TopHeader from '../../Header/TopHeader';
import Navbar from '../../Header/Navbar';
import Footer from '../../Footer/Footer';
import PopularService from '../PopularService';
import '../../Css/PropertiesShip.css';
import '../../Css/DocumentRequire.css';
import Swal from 'sweetalert2';

function Services() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [serviceDetail, setServiceDetail] = useState(null);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newBusiness, setNewBusiness] = useState([]);
    const location = useLocation();
    const [serviceTypeId, setServiceTypeId] = useState(null);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const typeId = queryParams.get('serviceTypeId');
        setServiceTypeId(typeId);
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.compliancekare.com/api/Compliance/ServiceDetailList?BusinessId=${id}`);
                setServiceDetail(response.data.id);
                if (response.data && response.data.services) {
                    setServices(response.data.services);
                } else {
                    setServices([]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        const fetchData1 = async () => {
            try {
                const { data } = await axios.get(
                    `https://api.compliancekare.com/api/Compliance/RegistrationProcessList?BusinessId=${id}`
                );
                setNewBusiness(data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData1();
        fetchData();
    }, [id]);


    const {
        serviceName = '',
        serviceTypeName = '',
        price = 0,
        marketPrice = 0,
        packageFee = 0,
        savePrice = 0,
        proprietorshipDescription = ''
    } = serviceDetail || {};

    const openRazorpay = async (amount, isPackage) => {
        const token = sessionStorage.getItem('token');
        const storedCouponCode = sessionStorage.getItem('couponCode') || '';

        if (!token) {
            Swal.fire({
                title: 'Please Login',
                text: 'Please login to get your services.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Login',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/Login');
                }
            });
            return;
        }

        Swal.fire({
            title: 'Do you have a coupon?',
            input: 'text',
            inputPlaceholder: 'Enter coupon code (if any)',
            inputValue: storedCouponCode, // Pre-fill with stored coupon code
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'No Coupon',
            closeButtonAriaLabel: 'Close this dialog',
        }).then(async (result) => {
            const couponCode = result.value || '';

            if (result.dismiss === Swal.DismissReason.cancel || couponCode === '') {
                // Direct Razorpay payment without coupon
                initiateRazorpayCheckout(amount, isPackage);
            } else if (result.isConfirmed) {
                // Verify coupon code
                try {
                    const verifyResponse = await axios.post(
                        'https://api.compliancekare.com/api/OrderApi/Verify',
                        {
                            couponCode,
                            amount,
                            serviceId: id
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            }
                        }
                    );

                    if (verifyResponse.data.isVeryfy) {
                        const finalAmount = verifyResponse.data.amount;
                        initiateRazorpayCheckout(finalAmount, isPackage, verifyResponse.data.isVeryfy);
                    } else {
                        Swal.fire('Invalid Coupon', verifyResponse.data.message, 'error');
                    }
                } catch (error) {
                    console.error('Error verifying coupon:', error);
                    Swal.fire('Error', 'There was an error verifying the coupon. Please try again later.', 'error');
                }
            }
        });
    };




    // Razorpay checkout logic in a separate function
    const initiateRazorpayCheckout = (finalAmount, isPackage, isCouponVerify) => {
        // Make sure finalAmount is in INR (e.g., 500 for ₹500)
        const options = {
            key: "rzp_test_BXjJAJuQSCxc3X", // Your Razorpay Key
            amount: Math.round(finalAmount * 100), // Multiply by 100 to convert INR to paise
            currency: "INR",
            name: "Compliance Kare",
            description: "File Incorporation Payment",
            image: "https://your-logo-url.com", // Add your company logo
            handler: async function (response) {
                // Successful payment handler
                Swal.fire('Payment Successful!', `Payment ID: ${response.razorpay_payment_id}`, 'success');

                // Prepare the data for the API call
                const orderData = {
                    serviceTypeId: serviceTypeId, // Set this if required
                    serviceId: id, // Adjust this as per your requirements
                    amount: finalAmount, // Amount in INR (not paise)
                    isPackage, // Pass the isPackage flag
                    isCouponVerify, // Include the isVerify flag
                    isPayment: true,
                    paymentStatus: "success",
                    transactionId: response.razorpay_payment_id // Razorpay payment ID
                };

                // Get token from sessionStorage
                const token = sessionStorage.getItem('token');

                // API call to save the order details
                try {
                    const apiResponse = await axios.post(
                        'https://api.compliancekare.com/api/OrderApi/Order',
                        orderData,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        }
                    );

                    console.log('Order successfully recorded:', apiResponse.data);
                } catch (error) {
                    console.error('Error recording the order:', error);
                    Swal.fire('Error', 'There was an error recording the order. Please contact support.', 'error');
                }
            },
            prefill: {
                name: "Your Name", // Prefill user details if available
                email: "email@example.com",
                contact: "9999999999",
            },
            theme: {
                color: "#F37254",
            },
        };

        // Open the Razorpay payment gateway
        const rzp = new window.Razorpay(options);

        // Handle payment failure
        rzp.on('payment.failed', async function (response) {
            const paymentId = response.error.payment_id || generateUniqueTransactionId();
            const orderId = response.error.order_id || generateUniqueTransactionId();
            const failureReason = response.error.description || 'Unknown error';

            Swal.fire('Payment Failed', `Transaction ID: ${paymentId}. Reason: ${failureReason}`, 'error');

            // Prepare the data for the API call
            const orderData = {
                serviceTypeId, // Set this if required
                serviceId: id, // Adjust this as per your requirements
                amount: finalAmount, // Amount in INR
                isPackage, // Pass the isPackage flag
                isCouponVerify, // Include the isVerify flag
                orderId, // Use Razorpay order ID if available
                isPayment: false,
                paymentStatus: "failed",
                transactionId: paymentId // Use Razorpay payment ID or generated ID
            };

            // Get token from sessionStorage
            const token = sessionStorage.getItem('token');

            // API call to save the order details
            try {
                const apiResponse = await axios.post(
                    'https://api.compliancekare.com/api/OrderApi/Order',
                    orderData,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                console.log('Order record update on failure:', apiResponse.data);
            } catch (error) {
                console.error('Error recording the order on failure:', error);
                Swal.fire('Error', 'There was an error recording the order. Please contact support.', 'error');
            }
        });

        rzp.open();
    };



    // Function to generate a unique transaction ID
    const generateUniqueTransactionId = () => {
        return 'TXN-' + new Date().getTime() + '-' + Math.floor(Math.random() * 10000);
    };




    return (
        <>
            <TopHeader />
            <Navbar />
            <div className='container'>
                <div className="bcca-breadcrumb">
                    <div className="bcca-breadcrumb-item">{serviceName}</div>
                    <div className="bcca-breadcrumb-item">{serviceTypeName}</div>
                    <div className="bcca-breadcrumb-item">
                        <Link to="/">Home</Link>
                    </div>
                </div>
                <div id="bg" />

                <div className="blg_row">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="blg_card" style={{ height: "330px", borderRadius: "11px", boxShadow: "1px 1px 0px 2px gray" }}>
                                    <div className='d-flex justify-content-between px-1' style={{ background: "#115887", borderRadius: "4px" }}>
                                        <p className='text-white p-3 mt-2' style={{ fontSize: "20px" }}> Pricing Summary: ₹{price}.00</p>
                                        <button
                                            type="button"
                                            className="btn mt-3 px-2"
                                            style={{ background: "green", height: "40px" }}
                                            onClick={() => openRazorpay(price, false)}
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                    <div className='p-3'>
                                        <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                            <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" alt="" />
                                            <p>Market Price:</p><s>₹{marketPrice}.00</s>
                                        </li>
                                        <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                            <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" alt="" />
                                            <p>You Save:</p>₹{savePrice}.00
                                        </li>
                                        <li style={{ listStyle: "none" }} className='d-flex gap-2'>
                                            <img style={{ width: "20px", height: "20px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU" alt="" />
                                            <p>Government Fee:</p><p className='text-success'>Included</p>
                                        </li>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="blg_card" style={{ height: "330px", borderRadius: "11px", boxShadow: "1px 1px 0px 2px gray" }}>
                                    <div className='d-flex justify-content-between px-1' style={{ background: "#115887", borderRadius: "4px" }}>
                                        <p className='text-white p-3 mt-2 ' style={{ fontSize: "22px" }}>Startup Package fees :₹{packageFee}.00</p>
                                        <button
                                            type="button"
                                            className="btn mt-3 px-2"
                                            style={{ background: "green", height: "40px" }}
                                            onClick={() => openRazorpay(packageFee, true)}
                                        >
                                            Get Started
                                        </button>

                                    </div>

                                    <div className='row blg_card'>
                                        {services && services.map((item, index) => (
                                            <div key={index} className='col-lg-6 col-md-6 col-sm-12 d-flex align-items-center'>
                                                <img
                                                    style={{ width: "20px", height: "20px", marginRight: "10px" }}
                                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSb226LzcEEHDKh_mze5ACRs3Dm8xP4eIT3Q&usqp=CAU"
                                                    alt=""
                                                />
                                                <p className='text-paraa mt-3'>{item.service}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                <div className="blg_left_column">
                    <div className="blg_card">
                        <div className="pg_main">
                            <h1>{serviceName}</h1>
                            <hr />
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: proprietorshipDescription || '' }}></div>
                            </div>
                        </div>
                    </div>

                    <div className="blg_card">
                        {newBusiness.length > 0 && (
                            <>
                                <h2 className="text-center mb-5">
                                    {newBusiness[0]?.serviceTypeName} Registration Process
                                </h2>
                                {newBusiness.map((process, index) => (
                                    <div className="row align-items-center mb-5" key={index}>
                                        {/* Conditionally render image and text based on even/odd index */}
                                        {index % 2 === 0 ? (
                                            <>
                                                <div className="col-sm-4 text-center">
                                                    <img
                                                        src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                                                        alt="Document Verification Icon"
                                                        className="img-fluid w-50"
                                                        style={{ borderRadius: "50%" }}
                                                    />
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="description p-4">
                                                        <h4 className="font-weight-bold">{process.days}</h4>
                                                        <div
                                                            className="text-muted"
                                                            dangerouslySetInnerHTML={{ __html: process.registrationProcess }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-sm-8">
                                                    <div className="description p-4">
                                                        <h4 className="font-weight-bold">{process.days}</h4>
                                                        <div
                                                            className="text-muted"
                                                            dangerouslySetInnerHTML={{ __html: process.registrationProcess }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 text-center">
                                                    <img
                                                        src="https://cdn-icons-png.flaticon.com/512/3135/3135712.png"
                                                        alt="Compliance Approval Icon"
                                                        className="img-fluid w-50"
                                                        style={{ borderRadius: "50%" }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>

                </div>

                <PopularService />
            </div>
            <Footer />
        </>
    );
}

export default Services;
