import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <div>
                <footer
                    class="text-center text-lg-start text-white"
                    style={{ backgroundColor: "#1c2331" }}>
                    <section class="py-2">
                        <div class="container ms-auto text-center text-md-start mt-5">

                            <div class="row mt-3">
                                <div class="col-md-2 col-lg-3 col-xl-3 ">

                                    <h6 class="text-uppercase fw-bold">Quick Links</h6>
                                    <hr
                                        class="mb-4 mt-0 d-inline-block mx-auto"
                                        style={{ width: "60px", height: "2px" }}
                                    />
                                    <p>
                                        <Link to="/about" class="text-white">About</Link>
                                    </p>
                                    <p>
                                        <Link to="/Contact" class="text-white">Contact</Link>
                                    </p>
                                    <p>
                                        <Link to="/Blog" class="text-white">Blog</Link>
                                    </p>
                                    <p>
                                        <Link to="/Faq" class="text-white">FAQ</Link>
                                    </p>
                                    <p>
                                        <Link to="/Privacy" class="text-white">Privacy Policy</Link>
                                    </p>
                                    <p>
                                        <Link to="/Termscondition" class="text-white">Terms & Conditions</Link>
                                    </p>
                                </div>
                                <div class="col-md-2 col-lg-3 col-xl-3  " >

                                    <h6 class="text-uppercase fw-bold">Compliance</h6>
                                    <hr
                                        class="mb-4 mt-0 d-inline-block mx-auto"
                                        style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                    />
                                    <p>
                                        <a href="#!" class="text-white">MSME Registration</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">FSSAI Registration</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">Trademark Registration</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">ISO certification</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">Company law compliance</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">LLP law compliance</a>
                                    </p>
                                </div>
                                <div class="col-md-2 col-lg-3 col-xl-3  " >

                                    <h6 class="text-uppercase fw-bold">Compliance</h6>
                                    <hr
                                        class="mb-4 mt-0 d-inline-block mx-auto"
                                        style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                    />
                                    <p>
                                        <a href="#!" class="text-white">MSME Registration</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">FSSAI Registration</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">Trademark Registration</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">ISO certification</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">Company law compliance</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">LLP law compliance</a>
                                    </p>
                                </div>

                                <div class="col-md-3 col-lg-3 col-xl-3  ">

                                    <h6 class="text-uppercase fw-bold ">Investments</h6>
                                    <hr
                                        class="mb-4 mt-0 d-inline-block  "
                                        style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                                    />
                                    <p c>
                                        <a href="#!" class="text-white">Mutual fund</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">Demat account</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">Investment in real estate</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">Fixed Deposits</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">Bonds</a>
                                    </p>
                                    <p>
                                        <a href="#!" class="text-white">life Insurance</a>
                                    </p>
                                </div>

                            </div>

                        </div>
                    </section>




                    <div
                        class="text-center p-3"
                       

                    >
                        © 2023 Copyright:
                        <a class="text-white" href="https://compliancekare.com/"
                        >Compliancekare.com</a
                        > Design & Development by <a href="https://ndtechland.com/" target='_blank' style={{color :"#4CAF50"}}>ND Techland Pvt. Ltd</a>
                    </div>

                </footer>


            </div>

        </>
    )
}

export default Footer