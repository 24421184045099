// import React from 'react'
// import '../Css/TaxService.css'
// import Tax1 from '../Images/download__9_-removebg-preview.png'


// function TaxServices() {
//   return (
//     <>
//       <div className="main">
//         <div className="container">
//           <div className="title textCenter ">
//             <h2>Our Tax Services</h2>

//           </div>
//           <div className="all flex">
//             <div className="small-cont relativ">
//               <section className="box1 relativ   flex" style={{ color: "black" }}>
//                 <div className="slize">
//                   <div className="info-p1">
//                     <h2 className='Text_heading'>Individual Tax Services</h2>
//                     <p className="parag para Para_width ">
//                       From tax planning to filing, we assist individuals in optimizing their tax positions, ensuring compliance with the latest tax laws.

//                     </p>
//                   </div>
//                 </div>
//                 <div className="slize">
//                   <div className="info-p2">
//                     <h2 className='Text_heading'>Business Tax Services</h2>
//                     <p className="parag para Para_width">
//                       Our comprehensive business tax solutions cover everything from strategic tax planning to compliance, helping businesses minimize tax liabilities and enhance profitability.
//                     </p>
//                   </div>
//                 </div>
//               </section>
//               <div className="img-center textCenter">
//                 <div className="overlay">
//                   <div className="info">
//                     <h2 className='text_top '>Accounting Services </h2>
//                     <p>
//                       Our suite of services is designed to cater to businesses of all sizes and industries. Whether you're a startup, a small business, or a large corporation, we offer a range of Accounting services tailored to meet your specific needs. From bookkeeping and payroll processing to tax planning and financial analysis, we are your one-stop solution for all.
//                     </p>
//                   </div>
//                 </div>
//                 <div className="img1">
//                   <img
//                     className="style hover animat"
//                     src={Tax1}
//                     alt=""
//                   />
//                 </div>
//                 <div className=" img2">
//                   <img
//                     className="style hover animat"
//                     src={Tax1}
//                     alt=""
//                   />
//                 </div>
//                 <div className=" img3">
//                   <img
//                     className="style hover animat"
//                     src={Tax1}
//                     alt=""
//                   />
//                 </div>
//                 <div className=" img4">
//                   <img
//                     className="style hover animat"
//                     src={Tax1}
//                     alt=""
//                   />
//                 </div>
//               </div>
//               <section className="box2  relativ flex" style={{ color: "black" }}>
//                 <div className="slize">
//                   <div className="info-p3">
//                     <h2 className='Text_heading'>Tax Consulting</h2>
//                     <p className="parag para Para_width">
//                       Leverage our expertise for strategic tax advice, specialized consulting, and navigating complex tax issues with confidence.
//                     </p>
//                   </div>
//                 </div>
//                 <div className="slize">
//                   <div className="info-p4">
//                     <h2 className='Text_heading'>Tax Representation </h2>
//                     <p className="parag para Para_width">
//                       Cases where representation is required before various authorities including but not limited to Income Tax Department and GST Trademark, RERA, FSSAI, DGFT our experienced team stands by your side, providing steadfast representation to protect your interests.
//                     </p>
//                   </div>
//                 </div>
//               </section>
//             </div>
//           </div>
//         </div>
//       </div>




//     </>
//   )
// }

// export default TaxServices



import React from 'react';
import './TaxServices.css';
import { FaUser, FaBriefcase, FaChartLine, FaShieldAlt } from 'react-icons/fa';

function TaxServices() {
  return (
    <div className="tax-services-container">
      <div className="title text-center">
        <h2>Our Tax Services</h2>
      </div>

      <div className="services-grid">
        <div className="service-card">
          <div className="icon">
            <FaUser size={60} />
          </div>
          <h3 className="card-heading">Individual Tax Services</h3>
          <p className="card-paragraph">
            From tax planning to filing, we assist individuals in optimizing their tax positions, ensuring compliance with the latest tax laws.
          </p>
        </div>
        
        <div className="service-card">
          <div className="icon">
            <FaBriefcase size={60} />
          </div>
          <h3 className="card-heading">Business Tax Services</h3>
          <p className="card-paragraph">
            Our comprehensive business tax solutions cover everything from strategic tax planning to compliance, helping businesses minimize tax liabilities and enhance profitability.
          </p>
        </div>
        
        <div className="service-card">
          <div className="icon">
            <FaChartLine size={60} />
          </div>
          <h3 className="card-heading">Tax Consulting</h3>
          <p className="card-paragraph">
            Leverage our expertise for strategic tax advice, specialized consulting, and navigating complex tax issues with confidence.
          </p>
        </div>
        
        <div className="service-card">
          <div className="icon">
            <FaShieldAlt size={60} />
          </div>
          <h3 className="card-heading">Tax Representation</h3>
          <p className="card-paragraph">
            Cases where representation is required before various authorities including but not limited to Income Tax Department and GST Trademark, RERA, FSSAI, DGFT our experienced team stands by your side, providing steadfast representation to protect your interests.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TaxServices;
