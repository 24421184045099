import React, { useState } from 'react';

function ThreeStepForm() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({

    });



    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrev = () => {
        setStep(step - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform form submission logic here
        console.log('Form submitted:', formData);
    };

    return (
        <div className="container h-100">
            <div className="row h-100">
                <div className="col-sm-10 col-md-8 col-lg-12 mx-auto d-table h-100">
                    <div className="d-table-cell align-middle">
                        {step === 1 && (
                            <div class="container h-100">
                                <div class="row h-100">
                                    <div class=" d-table h-100">
                                        <div class="d-table-cell align-middle">

                                        <p class="ml-2"><b>Note:</b>Please provide information about the person you are referring.</p>

                                            <div class="card">
                                              
                                                <div class="card-body">
                                                    <div class="m-sm-4">
                                                        <form>
                                                            <div class="form-group">
                                                                <label>Name</label>
                                                                <input class="form-control form-control-lg" type="text" name="name" />
                                                            </div>

                                                            <div class="form-group">
                                                                <label>Email</label>
                                                                <input class="form-control form-control-lg" type="email" name="email" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Mobile No</label>
                                                                <input class="form-control form-control-lg" type="password" name="password" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Referral No</label>
                                                                <input class="form-control form-control-lg" type="password" name="password" />
                                                            </div>
                                                            <div class="text-center mt-3">


                                                                <button type="button" class="btn btn-lg btn-primary" onClick={handleNext}>Next</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {step === 2 && (
                            <div class="container h-100">
                                <div class="row h-100">
                                    <div class=" d-table h-100">
                                        <div class="d-table-cell align-middle">



                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="m-sm-4">
                                                        <form>
                                                            <div class="form-group">
                                                                <label>Name</label>
                                                                <input class="form-control form-control-lg" type="text" name="name"  />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Company</label>
                                                                <input class="form-control form-control-lg" type="text" name="company"  />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Email</label>
                                                                <input class="form-control form-control-lg" type="email" name="email"  />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Password</label>
                                                                <input class="form-control form-control-lg" type="password" name="password"  />
                                                            </div>
                                                            <div class="text-center mt-3">


                                                                <button type="button" class="btn btn-lg btn-primary" onClick={handlePrev}>Previous</button>
                                                                <button type="button" class="btn btn-lg btn-primary" onClick={handleNext}>Next</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {step === 3 && (
                            <div class="container h-100">
                                <div class="row h-100">
                                    <div class="  d-table h-100">
                                        <div class="d-table-cell align-middle">

                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="m-sm-4">
                                                        <form>
                                                            <div class="form-group">
                                                                <label>Name</label>
                                                                <input class="form-control form-control-lg" type="text" name="name" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Company</label>
                                                                <input class="form-control form-control-lg" type="text" name="company"  />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Email</label>
                                                                <input class="form-control form-control-lg" type="email" name="email"  />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Password</label>
                                                                <input class="form-control form-control-lg" type="password" name="password"  />
                                                            </div>
                                                            <div class="text-center mt-3">


                                                                <button type="button" class="btn btn-lg btn-primary" onClick={handlePrev}>Previous</button>
                                                                <button type="button" class="btn btn-lg btn-primary">Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThreeStepForm;
